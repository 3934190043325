import React from 'react'

import {
	Box,
	Button,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Skeleton,
	Stack,
	styled,
	TextField,
	Typography,
} from '@mui/material'
import Loader from 'components/shared/loader'
import { Form, FormikProvider, useFormik } from 'formik'
import { capitalize } from 'lodash'
import { canCreate } from 'pages/Admin/helpers'
import { SYSTEM_ID } from 'pages/Admin/system-settings/constants'
import type { FC } from 'react'
import type { AssetWithParent, TagWithParent, Variant } from 'shared/types'
import * as Yup from 'yup'

import { useCopyAndMove } from './hooks/useCopyAndMove'

const TextInput = styled(TextField, { label: 'text-input' })`
	margin-top: 0.2rem;
	min-width: 19rem;
	input {
		min-width: 19rem;
		height: 0.5rem;
	}
`

const ClickableBox = styled(Box, {
	label: 'clickable-box',
	shouldForwardProp: prop => prop !== 'disabled',
})(
	({ disabled = false }: { disabled?: boolean }) => `
        cursor: pointer;
        margin-top: 0 !important;
        padding-right: 0.3rem;
        &:hover {
            text-decoration: underline;
		}
        
        ${
			disabled &&
			`
            cursor: auto;
            &:hover {
                text-decoration: none;
            }
        `
		}
`
)

const ClickableListBox = styled(ClickableBox, { label: 'clickable-box' })`
	padding: 0.3rem;
	&:hover {
		background-color: #f6f6f6;
	}
`

interface Props {
	onSave: (name: string, newParentId: string) => void
	onClose: () => void
	type: 'copy' | 'move'
	name: string
	isAdmin: boolean
	selectedApp: Variant
	resource: AssetWithParent | TagWithParent
}

const CopyAndMoveForm: FC<Props> = ({
	onSave,
	onClose,
	name = '',
	type,
	isAdmin,
	selectedApp,
	resource,
}) => {
	const {
		selectedWorkspace,
		selectedApplication,
		selectedVar,
		onResourceClick,
		onBreadcrumbClick,
		selectableResources,
		breadcrumbResources,
		isLoadingWorkspaces,
	} = useCopyAndMove({ resource, selectedVariant: selectedApp, isAdmin })

	const formik = useFormik({
		initialValues: {
			name,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().max(255).required('Name is required'),
		}),
		onSubmit: (values, { setSubmitting }) => {
			const newParentId =
				selectedVar?._id ??
				selectedApplication?._id ??
				selectedWorkspace?._id ??
				(isAdmin && SYSTEM_ID)

			if (!newParentId) return

			onSave(values.name, newParentId)
			setSubmitting(false)
		},
	})

	const { errors, touched, handleSubmit, isSubmitting, getFieldProps } =
		formik

	if (isSubmitting) {
		return <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
	}

	const isLoading = isLoadingWorkspaces

	if (isLoading) return <Loader />

	return (
		<FormikProvider value={formik}>
			{isLoading ? (
				<Loader />
			) : (
				<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<Grid container spacing={3}>
						<DialogTitle
							display="flex"
							flexDirection="row"
							alignItems="center"
						>
							<Typography marginX={1}>
								{capitalize(type)}
							</Typography>
							<TextInput
								id="tag-name"
								placeholder="Enter Tag Name"
								{...getFieldProps('name')}
								error={Boolean(touched.name && errors.name)}
								helperText={touched.name && errors.name}
							/>
							<Typography marginLeft={1}>to...</Typography>
						</DialogTitle>
					</Grid>
					<Stack
						margin={1}
						spacing={3}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-start"
					>
						<ClickableBox
							role="button"
							disabled={!isAdmin}
							key={SYSTEM_ID}
							onClick={() => {
								if (!isAdmin) return
								onBreadcrumbClick({ type: 'instance' })
							}}
						>
							<Typography>ASTA</Typography>
						</ClickableBox>
						{breadcrumbResources.length > 0
							? breadcrumbResources.map(res => {
									return (
										<ClickableBox
											role="button"
											disabled={
												!canCreate(res.role) && !isAdmin
											}
											key={resource._id}
											onClick={() => {
												if (
													!canCreate(res.role) &&
													!isAdmin
												)
													return
												onBreadcrumbClick(res)
											}}
										>
											<Typography>
												{` > ${res.name} `}
											</Typography>
										</ClickableBox>
									)
								})
							: ''}
					</Stack>
					<Divider />
					<DialogContent
						sx={{
							p: 2.5,
							height: 140,
							overflowY: 'scroll',
						}}
					>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Stack spacing={1.25}>
									{selectableResources
										.filter(r => canCreate(r.role))
										.map(resource => (
											<ClickableListBox
												role="button"
												sx={
													resource._id ===
														selectedVar?._id && {
														backgroundColor:
															'#F1F6FF',
													}
												}
												key={resource._id}
												onClick={() =>
													onResourceClick(resource)
												}
											>
												{resource.name}
											</ClickableListBox>
										))}
								</Stack>
							</Grid>
						</Grid>
					</DialogContent>
					<Box
						width="100%"
						display="flex"
						justifyContent="flex-end"
						my="1"
					>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							sx={{ marginRight: 2 }}
							disabled={!isAdmin && !selectedWorkspace}
						>
							{capitalize(type)}
						</Button>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onClose}
							sx={{ marginRight: 4 }}
						>
							Cancel
						</Button>
					</Box>
				</Form>
			)}
		</FormikProvider>
	)
}

export default CopyAndMoveForm
